<script setup>
import { ref, computed, inject, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import cdnFile from '@/helpers/cdnFile'
import { useToast } from 'vue-toast-notification'
import RegisterModal from '@/components/RegisterModal.vue'
import { createRandomAttributes } from '@/helpers/createRandomAttributes'
import { useHead } from '@unhead/vue'
import Chat from '@/components/icons/Chat.vue'
import Generate from '../components/icons/Generate.vue'
import AppHeader from '@/components/AppHeader.vue'
import refresh from '@/assets/icons/refresh-2.svg'
import ButtonComponent from '../components/ButtonComponent.vue'
import { useMixpanel } from '@/composables/mixpanel'
import { createTimeElapsedCounter } from '@/helpers/timeElapsed'
import { storeToRefs } from 'pinia'
import { useCreateModelStore } from '@/stores/createModel'
import logo from '@/assets/logo.svg'
import queryString from 'query-string'
import { encryptURIComponent } from '@/helpers/uriEncrypt'
import sleep from '@/helpers/sleep'
import isServerOverloaded from 'common/lib/helpers/errorHelpers.js'
import safeGenerateRecaptchaToken from '@/helpers/safeGenerateRecaptchaToken.js'
import getRecaptchaExpectedAction from 'common/lib/helpers/getRecaptchaExpectedAction.js'

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | View AI Character`,
  meta: [{ name: 'robots', content: 'noindex' }],
})

const mixpanel = useMixpanel()

const $store = useStore()
const $route = useRoute()
const $router = useRouter()
const $toast = useToast()

const createModelFunnelId = $route.query.funnel_id
const createModelFunnelTimeElapsed = createTimeElapsedCounter(
  $route.query.funnel_time_elapsed || 0,
)

const orphanModel = ref(null)

const user = computed(() => $store.state.user)
const model = computed(() => orphanModel.value || $store.getters.model)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

const createModelStore = useCreateModelStore()
const { pendingModel } = storeToRefs(createModelStore)

const $axios = inject('axios')

const registerOpen = ref(false)
const validationOpen = ref(false)

const loadingLimit = 20
const loading = ref(false)
const loadingCounter = ref(loadingLimit)
const loadingIntervalID = ref(0)
const serverOverload = ref(false)

function setLoading(flag) {
  loading.value = flag
  if (flag) {
    loadingCounter.value = loadingLimit
    loadingIntervalID.value = setInterval(() => {
      loadingCounter.value -= 1
    }, 1000)
  } else {
    clearInterval(loadingIntervalID.value)
  }
  console.log(loading)
}

const loadingDisplayValue = computed(() =>
  loadingCounter.value > 0
    ? loadingCounter.value
    : loadingCounter.value > -10
      ? 'Just a few more seconds...'
      : loadingCounter.value > -20
        ? 'Getting there shortly...'
        : loadingCounter.value > -30
          ? 'Almost there...'
          : loadingCounter.value > -40
            ? 'Hang on tight...'
            : 'Any time now...',
)

setLoading(true)

const modelData = { ...pendingModel.value }

const generationCount = ref(0)

async function regenerate() {
  setLoading(true)
  serverOverload.value = false
  let newModel

  let newAttributes

  if (model.value.random) {
    newAttributes = createRandomAttributes(
      model.value.gender,
      model.value.style,
      ['gender', 'style'],
    )
  }

  mixpanel.track('create_model_regenerate_start', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: modelData,
    random: modelData.random,
  })

  const regenerateModelTimeElapsed = createTimeElapsedCounter()

  try {
    if (!user.value) {
      const postPath = '/model/regenerate'

      const captchaToken = await safeGenerateRecaptchaToken(
        getRecaptchaExpectedAction(postPath),
      )

      newModel = await $axios
        .post(
          postPath,
          { modelId: model.value.id, newAttributes },
          {
            headers: {
              'Captcha-Token': captchaToken,
            },
          },
        )
        .then((res) => res.data.model)
    } else {
      newModel = await $axios
        .post('/user/regenerate-model', {
          modelId: model.value.id,
          newAttributes,
        })
        .then((res) => res.data.model)
    }
  } catch (e) {
    setLoading(false)
    const message = e?.response?.data?.message

    if (isServerOverloaded(e)) {
      serverOverload.value = true
    } else if (message === 'Please register to continue generating') {
      $store.dispatch('openLoginRegisterPopup')
    } else {
      return $toast.error(message || 'An error occurred')
    }

    return
  }

  mixpanel.track('create_model_regenerate_finish', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: modelData,
    random: modelData.random,
    generation_time_elapsed: regenerateModelTimeElapsed(),
  })

  if (!user.value) {
    orphanModel.value = newModel
  } else {
    $store.commit('REPLACE_MODEL', {
      oldModelId: model.value.id,
      newModel,
    })
  }

  generationCount.value += 1

  setLoading(false)
}

function retry() {
  serverOverload.value = false
  regenerate()
}

function backButtonBlocker(event) {
  history.pushState(null, document.title, location.href)
}

function warnOnUnload(event) {
  const confirmationMessage =
    'Please wait for your girl to finish loading and then click on "Continue to chat" to proceed to the chat.'

  ;(event || window.event).returnValue = confirmationMessage
  return confirmationMessage
}

onMounted(async () => {
  nextTick(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  })

  mixpanel.track('create_model_created_start', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: modelData,
    random: modelData.random,
  })

  const createModelTimeElapsed = createTimeElapsedCounter()

  if (user.value) {
    let createModelData

    try {
      createModelData = await $axios
        .post('/user/create-model', {
          model: modelData,
        })
        .then((res) => res.data)
    } catch (e) {
      setLoading(false)
      const message = e?.response?.data?.message
      if (isServerOverloaded(e)) {
        serverOverload.value = true
      } else if (message === 'Trial model cap reached') {
        $store.dispatch('openSubscribePopup')
      } else {
        return $toast.error(message || 'An error occurred')
      }
      return
    }

    if (user.value?.subscription)
      $store.dispatch('changeLuna', -createModelData.changedLuna)
    $store.commit('ADD_MODEL', createModelData.model)
    $store.commit('SET_SELECTED_MODEL', createModelData.model.id)
    $store.dispatch('setMessages', createModelData.model.messages)
    setLoading(false)
  } else {
    const postPath = '/model/create'

    const captchaToken = await safeGenerateRecaptchaToken(
      getRecaptchaExpectedAction(postPath),
    )

    let createModelData
    try {
      createModelData = await $axios
        .post(
          postPath,
          {
            model: modelData,
          },
          {
            headers: {
              'Captcha-Token': captchaToken,
            },
          },
        )
        .then((res) => res.data)
    } catch (e) {
      setLoading(false)
      const message = e?.response?.data?.message
      if (isServerOverloaded(e)) {
        serverOverload.value = true
      } else if (message === 'Trial model cap reached') {
        $store.dispatch('openLoginRegisterPopup')
      } else if (message === 'Please register to continue generating') {
        $store.dispatch('openLoginRegisterPopup')
      } else {
        return $toast.error(message || 'An error occurred')
      }
      return
    }

    orphanModel.value = createModelData.model
    setLoading(false)
  }

  mixpanel.track('create_model_created_finish', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: modelData,
    random: modelData.random,
    generation_time_elapsed: createModelTimeElapsed(),
  })

  generationCount.value += 1

  // Block back button
  // history.pushState(null, document.title, location.href);
  // window.addEventListener('popstate', backButtonBlocker)
  // window.addEventListener('beforeunload', warnOnUnload)
})

// onUnmounted(() => {
// window.removeEventListener('popstate', backButtonBlocker)
// window.removeEventListener('beforeunload', warnOnUnload)
// })

function continueToChat() {
  if (!user.value) {
    // window.removeEventListener('beforeunload', warnOnUnload)
    registerOpen.value = true
    return
  }

  mixpanel.track('create_model_finish', {
    funnel_id: createModelFunnelId,
    funnel_time_elapsed: createModelFunnelTimeElapsed(),
    generation_count: generationCount.value,
    model_metadata: model.value,
    random: model.value.random,
    redirect_to: $route.query.from === 'generator' ? 'generator' : 'chat',
  })

  const redirectQuery = queryString.stringify({
    modelId: encryptURIComponent(model.value.id),
  })

  $router.push(
    ($route.query.from === 'generator' ? '/generator' : '/chat') +
      '?' +
      redirectQuery,
  )
}

const createRedirectUrl = () => {
  const redirectQuery = queryString.stringify({
    modelId: encryptURIComponent(model.value.id),
  })

  return `${$route.query.from === 'generator' ? 'generator' : 'chat'}?${redirectQuery}`
}

$store.commit('UPDATE_LAST_APP_PAGE')
</script>

<template>
  <div
    class="lg:ml-[280px] pb-[100px] lg:pb-[19px] sm:h-auto"
    :class="discountPopupOpen ? 'pt-[156px]' : ''"
  >
    <AppHeader />
    <div class="lg:mx-auto px-8 lg:px-0 flex flex-col items-center">
      <!--      <button
        @click="simulateServerOverload"
        class="mb-4 px-4 py-2 bg-blue-500 text-white rounded"
      >
        Simulate Server Overload
      </button>-->
      <div
        v-if="serverOverload"
        @click="retry"
        class="cursor-pointer relative p-[3px] rounded-[25px] w-full max-w-[500px] h-auto aspect-[832/1216] bg-gray-400 flex flex-col justify-center items-center"
      >
        <img
          class="w-[60px] h-auto mx-auto"
          :src="refresh"
          style="filter: invert(0) brightness(0)"
        />
        <p
          class="text-[25px] lg:text-[25px] text-center leading-[47px] mb-4 mt-5 px-[25px] text-black font-semibold"
        >
          Our servers are currently overloaded, please try again shortly.
        </p>
      </div>

      <template v-else>
        <template v-if="loading">
          <p class="text-[30px] lg:text-[35px] text-center leading-[47px] mb-4">
            Creating your
            {{ modelData.gender === 'female' ? 'girlfriend' : 'boyfriend' }}
          </p>
          <p class="text-center font-normal text-[#7D829E]">
            Please wait and do not close your browser
          </p>
        </template>

        <div v-if="model && !loading" class="text-white mb-10 text-center">
          <template v-if="!registerOpen">
            <p
              class="text-[30px] lg:text-[35px] leading-[30px] lg:leading-[35px]"
            >
              Say Hello to {{ (pendingModel || model)?.name }}!
            </p>
          </template>
          <template v-else> Create a Free Account </template>
        </div>
        <div
          class="lg:mx-auto w-full flex flex-col justify-center items-center"
          :class="registerOpen ? 'lg:max-w-[472px]' : ''"
        >
          <div class="w-full flex flex-col items-center justify-center">
            <div
              v-if="loading"
              class="mt-[71px] relative w-[240px] h-[240px] rounded-full border-[20px] border-[#A15CFF] border-opacity-30"
            >
              <div
                class="h-[228px] w-[228px] absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-full"
                style="
                  box-shadow:
                    0px 0px 30px 0px #cc47ff,
                    0px 0px 30px 0px #cc47ff inset;
                "
              >
                <div class="loader border-b-transparent w-full h-full"></div>
              </div>
              <p
                class="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-[75px]"
              >
                {{ loadingDisplayValue > 0 ? loadingDisplayValue : '' }}
              </p>
            </div>
            <div v-if="loading" class="text-[25px] leading-[33px] mt-[34px]">
              {{ isNaN(loadingDisplayValue) ? loadingDisplayValue : '' }}
            </div>
            <div
              v-else-if="model"
              class="relative p-[3px] rounded-[25px] w-full max-w-[500px] h-auto"
              style="
                background: linear-gradient(
                  19.86deg,
                  #a359ff 10.28%,
                  rgba(184, 0, 255, 0) 83.75%
                );
              "
            >
              <img
                :src="cdnFile(model.referenceImage)"
                class="relative h-auto object-cover rounded-[25px] select-none w-full max-w-[500px] aspect-[832/1216]"
                style="
                  user-select: none;
                  user-drag: none;
                  -webkit-user-drag: none;
                  pointer-events: none;
                "
                draggable="false"
              />
            </div>
          </div>
          <template v-if="!loading && !registerOpen">
            <ButtonComponent
              :disabled="loading"
              class="flex justify-center items-center gap-[15px] mt-[17px] py-[14px] lg:max-w-[338px] w-full"
              @click="continueToChat"
            >
              Continue to
              {{
                $route.query.from === 'generator' ? 'image generator' : 'chat'
              }}
              <Chat
                v-if="
                  $route.query.from === 'chat' ||
                  $route.query.from === 'undefined' ||
                  $route.query.from === undefined
                "
                :color="white"
                :size="24"
              />
              <Generate v-else />
            </ButtonComponent>
            <p class="text-center my-[19px] lg:max-w-[338px] w-full">
              Don't like how

              {{ model?.gender === 'female' ? 'she' : 'he' }} looks?
            </p>
            <ButtonComponent
              :disabled="loading"
              variant="secondary"
              :borderVisible="false"
              class="flex justify-center items-center gap-[10px] py-[15px] lg:max-w-[338px] w-full"
              @click="regenerate"
            >
              Click to regenerate.
              <img :src="refresh" />
            </ButtonComponent>
            <div v-if="!user?.models?.length" class="text-center mt-5 text-sm">
              You'll be able to create more
              {{ model?.gender === 'female' ? 'girls' : 'men' }} inside the app
            </div>
          </template>
          <h2
            class="my-[25px] text-[35px] lg:text-[38px] leading-9 tracking-tight text-white"
            v-if="validationOpen"
          >
            Verify your Email
          </h2>
          <RegisterModal
            v-if="registerOpen"
            :with-accept-policies="true"
            :cta-text="`Meet ${pendingModel.name}`"
            class="mt-10 w-full"
            :model-ids="[orphanModel.id]"
            :redirectPath="createRedirectUrl()"
            @registered="continueToChat"
            @validationOpen="validationOpen = true"
          />
        </div>
      </template>
    </div>
  </div>
</template>
