import premadeModels from 'common/lib/premade.js'

const femaleModels = premadeModels
  .filter((pmodel) => pmodel.model.gender === 'female')
  .map((pmodel) => pmodel.model.name.toLowerCase())
const maleModels = premadeModels
  .filter((pmodel) => pmodel.model.gender === 'male')
  .map((pmodel) => pmodel.model.name.toLowerCase())

const femaleModelRoutes = femaleModels.map((model) => `/ai-girlfriend/${model}`)
const maleModelRoutes = maleModels.map((model) => `/ai-boyfriend/${model}`)

export default [
  '/',
  '/ai-hentai',
  '/ai-boyfriend',
  '/generator',
  '/rater',
  '/affiliate',
  '/login',
  '/register',
  '/forgot-password',
  '/reset-password',
  '/terms-of-service-eea',
  '/terms-of-service-row',
  '/privacy-policy',
  '/cancellation-policy',
  ...femaleModelRoutes,
  ...maleModelRoutes,
]
