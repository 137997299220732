import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toast-notification'
import { useRouter } from 'vue-router'
import { useMixpanel } from './mixpanel.js'

export default function useNavigationMenu() {
  const $store = useStore()
  const $router = useRouter()
  const $toast = useToast()
  const $axios = inject('axios')
  const mixpanel = useMixpanel()

  const user = computed(() => $store.state.user)

  function goHome(fromLogoMenu = false) {
    if (fromLogoMenu === true) {
      mixpanel.track('logo_menu_click')
    } else {
      mixpanel.track('explore_menu_click')
    }
    $router.push('/')
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })
    return false
  }

  function goToGenerator() {
    mixpanel.track('generator_menu_click')
    $router.push('/generator')
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })
    return false
  }

  function goToChat() {
    mixpanel.track('chat_menu_click')
    if (!user.value) {
      $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
        open: true,
      })

      return
    }

    $router.push('/chat')

    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })

    return false
  }

  function goToSaved() {
    mixpanel.track('saved_menu_click')
    if (!user.value) {
      $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
        open: true,
      })

      return
    }

    $router.push('/saved')

    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })

    return false
  }

  function goToRater() {
    mixpanel.track('rater_menu_click')
    $router.push('/rater')
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })
    return false
  }

  function goToSettings() {
    mixpanel.track('settings_menu_click')
    if (!user.value) {
      $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
        open: true,
      })

      return
    }

    $router.push('/settings')

    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })

    return false
  }

  function goToAffiliate() {
    mixpanel.track('affiliate_menu_click')
    $router.push('/affiliate')

    return false
  }

  async function logout() {
    mixpanel.track('logout_menu_click')

    $store.dispatch('setUser', null)

    $store.commit('SET_DISCOUNT_POPUP', {
      open: false,
    })

    $store.commit('SET_FREE_RATING', {})

    try {
      await $axios.post('/auth/signout')
    } catch (e) {
      const message = e?.response?.data?.message
      $toast.error(
        message || 'Unable to perform action. Please try again later.',
      )
      return
    }

    $router.push('/')
    $store.commit('SET_MOBILE_MENU_OPEN', {
      open: false,
    })
  }

  return {
    goHome,
    goToGenerator,
    goToChat,
    goToSaved,
    goToRater,
    goToSettings,
    goToAffiliate,
    logout,
  }
}
